




































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { objectIsEmpty } from '~/utils/common.js';
import { getSportTags } from '~/utils/explorer.js';

import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';

@Component({
  components: { ProfileRunSportTag, EventCategoryTag },
})
export default class DestinationCard extends Vue {
  @Prop(Object) news: Types.DestinationItem;

  /* Computed skeleton handle */
  get newsIsEmpty () {
    return objectIsEmpty(this.news);
  }

  /* Util to populate sports tags of associated element */
  get routeSportTags () {
    return getSportTags(this.news.sports);
  }

  mounted () {
    // This code resets the CSS inline styles of the description
    const description: any = document.getElementsByClassName('DestinationDescription');
    description.forEach((d: any) => {
      d.innerHTML = d.textContent;
    });
  }
}
